import * as httpStatus from '../const/httpCodes';

export default class ApiException extends Error {
  /**
     * @param {string} message
     * @param {number} [statusCode]
     * @param {Object<*>} [payload]
     */
  constructor(message, statusCode, payload) {
    super(message || 'Internal server error');

    this.name = this.constructor.name;
    this.statusCode = statusCode || httpStatus.INTERNAL_SERVER_ERROR;
    this.payload = payload || {};
  }
}
