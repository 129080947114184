/**
 * Оболочка над методами харнилищиа, если нужно заменить localStorage на webSQL это можно легко сделать
 */
import { STORAGE_AVAILABLE } from './constants';

const globalStorage = [];
export default class Storage {
  static isAvailable() {
    try {
      localStorage.setItem(STORAGE_AVAILABLE, '1');
      localStorage.removeItem(STORAGE_AVAILABLE);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
     * Сохраняет объект в БД
     * @param key
     * @param value
     */
  static set(key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    if (this.isAvailable()) {
      localStorage.setItem(key, value);
    } else {
      globalStorage[key] = value;
    }

    const detail = {
      key,
      value: JSON.parse(value),
    };
    const customStorageEvent = new CustomEvent('customStorage', { detail });
    window.dispatchEvent(customStorageEvent);
  }

  /**
     * Получает значение из БД
     * @param key
     * @returns {*}
     */
  static get(key) {
    let value;
    if (this.isAvailable()) {
      value = localStorage.getItem(key);
    } else {
      value = globalStorage[key];
    }

    if (typeof value === 'string') {
      try {
        value = JSON.parse(value);
      } catch (e) {
        console.error('Storage.get JSON parse error', value);
      }
    }

    return value;
  }

  static remove(key) {
    if (this.isAvailable()) {
      localStorage.removeItem(key);
    } else {
      delete globalStorage[key];
    }
  }
}
